<template>
  <v-container>
    <v-row class="justify-center ma-6">
      <MonthSelect
        :init-value="fromMonth"
        :label="$t('fromMonth')"
        v-on:change="setFromMonth"
      />
      <MonthSelect
        :init-value="toMonth"
        :label="$t('toMonth')"
        v-on:change="setToMonth"
      />
      <v-btn @click="update" class="ml-6 mt-2" data-testid="fetchStats">{{
        $t("fetch")
      }}</v-btn>
    </v-row>

    <v-row v-if="departmentName">
      <v-col cols="12">
        <v-alert type="info" outlined>
          {{ $t("statsDepartmentFilterInfo", { department: departmentName }) }}
        </v-alert>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        {{ $t("summarized") }}
      </v-card-title>
      <v-card-text>
        <AlertStatsSummarized
          :alert-stats-sum="statsData.Sum"
          v-if="statsData"
        />
      </v-card-text>
    </v-card>

    <v-card class="mt-8">
      <v-card-title>
        {{ $t("alerts") }}
      </v-card-title>
      <v-card-text>
        <AlertStatsTable
          v-if="statsData"
          :alert-stats="statsData"
          :from-month="fromMonth"
          :to-month="toMonth"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import MonthSelect from "@/components/time/MonthSelect";
import apiAlert from "@/api/v24/api.alert";
import timeUtil from "@/util/timeUtil";
import AlertStatsTable from "@/components/stats/AlertStatsTable";
import AlertStatsSummarized from "@/components/stats/AlertStatsSummarized";
import store from "@/store";
import user from "@/util/user";
import storage from "@/util/storage";

const sessionKeyFromMonth = "statsFromMonth";
const sessionKeyToMonth = "statsToMonth";

export default {
  name: "Stats",
  components: { AlertStatsSummarized, AlertStatsTable, MonthSelect },

  computed: {
    departmentName() {
      return store.getters.getSelectedDepartmentName;
    }
  },

  data: () => ({
    fromMonth: "",
    toMonth: "",
    statsData: null
  }),

  created() {
    this.fromMonth = getInitFromMonth();
    this.toMonth = getInitToMonth();
    this.update();
  },

  methods: {
    update() {
      const fromDateObj = new Date(this.fromMonth);
      const toDateObj = new Date(this.toMonth);
      const range = timeUtil.getDateRangeFromMonths(
        fromDateObj.getFullYear(),
        fromDateObj.getMonth() + 1,
        toDateObj.getFullYear(),
        toDateObj.getMonth() + 1
      );
      const department = user.getSelectedDepartment();
      apiAlert.getStats(range.from, range.to, department).then(data => {
        this.statsData = data;
      });
    },

    setFromMonth(event) {
      this.fromMonth = event.month;
      storage.setSessionItem(sessionKeyFromMonth, this.fromMonth);
    },

    setToMonth(event) {
      this.toMonth = event.month;
      storage.setSessionItem(sessionKeyToMonth, this.toMonth);
    }
  }
};

const getInitFromMonth = () => {
  const sessionValue = storage.getSessionItem(sessionKeyFromMonth);
  return sessionValue ? sessionValue : getDefaultMonth();
};

const getInitToMonth = () => {
  const sessionValue = storage.getSessionItem(sessionKeyToMonth);
  return sessionValue ? sessionValue : getDefaultMonth();
};

const getDefaultMonth = () => {
  const d = new Date();
  let month = timeUtil.getNumberAsTwoDigits(d.getMonth() + 1);
  return d.getFullYear() + "-" + month;
};
</script>
