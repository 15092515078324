import Vue from "vue";
import VueRouter from "vue-router";
import user from "../util/user";
import config from "../config/config";
import store from "../store";
import apiSession from "@/api/v24/api.session";

Vue.use(VueRouter);

const adminPath = "/admin/";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/LandingPage")
  },
  {
    path: "/alert",
    name: "Alert",
    component: () => import("../views/Alert")
  },
  {
    path: "/management",
    name: "ContactAndGroupManagement",
    component: () => import("../views/ContactAndGroupManagement")
  },
  {
    path: "/log",
    name: "Log",
    component: () => import("../views/Log")
  },
  {
    path: "/dsf-extract",
    name: "DsfExtract",
    component: () => import("../views/DsfExtract")
  },
  {
    path: "/alert-templates",
    name: "AlertTemplates",
    component: () => import("../views/AlertTemplates")
  },
  {
    path: "/files",
    name: "Files",
    component: () => import("../views/Files")
  },
  {
    path: "/user-profile",
    name: "UserPorfile",
    component: () => import("../views/UserProfile")
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import("../views/Feedback")
  },
  {
    path: "/usrMng",
    name: "UserManagement",
    component: () => import("../views/UserManagement")
  },
  {
    path: adminPath + "departments",
    name: "Departments",
    component: () => import("../views/Departments")
  },
  {
    path: adminPath + "roles",
    name: "Roles",
    component: () => import("../views/Roles")
  },
  {
    path: adminPath + "stats",
    name: "Stats",
    component: () => import("../views/Stats")
  },
  {
    path: "/scenario",
    name: "Scenario",
    component: () => import("../views/Scenario")
  },
  {
    path: "/r",
    name: "ReceiverResponse",
    component: () => import("../views/ReceiverResponseRegistration")
  },
  {
    path: "/notauth",
    name: "NotAuthorized",
    component: () => import("../views/NotAuthorized")
  },
  {
    path: "/new-user",
    name: "NewUser",
    component: () => import("../views/NewUser")
  },
  {
    path: "/sso-ms-login",
    name: "SsoMicrosoftLogin",
    component: () => import("../views/SsoMicrosoftLogin")
  },
  {
    path: "/sso-ms-loggedin",
    name: "SsoMicrosoftLoggedIn",
    component: () => import("../views/SsoMicrosoftLoggedIn")
  },
  {
    path: "/sso-ms-loggedout",
    name: "SsoMicrosoftLoggedout",
    component: () => import("../views/SsoMicrosoftLoggedout")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    // Just to avoid overdramatic exception in console when user by mistake
    // clicks on page in nav bar that he/she is already on.
    if (err.name !== "NavigationDuplicated") {
      throw err;
    }
  });
};

router.beforeEach((to, from, next) => {
  config.responseFeedbackVisitor = to.path === "/r";
  config.newUserVisitor = to.path === "/new-user";

  if (user.isLoggedIn()) {
    store.dispatch("updateUserProfile").then(usrProfile => {
      const permissions = usrProfile.Bruker._permissions;

      if (to.path === "/") {
        router.replace({ path: "alert" });
      }

      if (!usrProfile.Bruker.Admin) {
        if (from.path === "/" && !permissions.alert && to.path === "/alert") {
          router.replace("log");
        } else if (to.path.indexOf(adminPath) !== -1) {
          handleNoAuth(to);
        } else if (
          !permissions.alert &&
          (to.path === "/" || to.path === "/alert")
        ) {
          handleNoAuth(to);
        } else if (!permissions.alert && to.path === "/scenario") {
          handleNoAuth(to);
        } else if (!permissions.dsfModule && to.path === "/dsf-extract") {
          handleNoAuth(to);
        } else if (!permissions.manageContacts && to.path === "/management") {
          handleNoAuth(to);
        } else if (!permissions.template && to.path === "/alert-templates") {
          handleNoAuth(to);
        } else if (!permissions.manageUsers && to.path === "/usrMng") {
          handleNoAuth(to);
        }
      }

      if (to.path === "/sso-ms-loggedin") {
        const url = new URL(location.href);
        const ssoCode = url.searchParams.get("code");
        console.log(ssoCode);
        console.log(user.getSsoMsLastUsedCode());
        if (ssoCode && ssoCode === user.getSsoMsLastUsedCode()) {
          // If user comes here he/she is an SSO user that must have
          // pressed the "back" button
          // in the browser and come back to the authentication phase.
          // We will not route the user on with next() since this will lead
          // to a confusing UI state
          console.log("Route change prevented");
          return;
        }
      }

      next();
    });
  } else {
    next();
  }
});

const handleNoAuth = to => {
  store.commit("setRetryPageOnNoPermission", to.path);
  router.replace("notauth");
};

export default router;
