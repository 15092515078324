import Vue from "vue";
import Vuex from "vuex";
import apiGroup from "../api/v24/api.group";
import apiContact from "../api/v24/api.contact";
import department from "@/util/department";
import user from "../util/user";
import apiAlertTemplates from "../api/v24/apiAlertTemplates";
import apiAlert from "../api/v24/api.alert";
import apiVoice from "@/api/v24/api.voice";
import config from "@/config/config";
import storage from "@/util/storage";
import numberUtil from "@/util/numberUtil";

Vue.use(Vuex);

const keyStorageRowsPerPage = "rowsPerPage";

export default new Vuex.Store({
  state: {
    userProfile: null,
    groups: null,
    departments: null,
    contactsUpdate: null,
    voices: [],
    alertTemplates: [],
    alertList: null,
    alert: {
      selectedGroups: [],
      selectedContacts: [],
      settings: null,
      receivers: [],
      simMode: false
    },
    appSnackbar: {
      text: "",
      visible: false,
      navButton: {
        text: "",
        path: ""
      }
    },
    tagName: "",
    ssoMsLastUsedCode: "",
    roles: [],
    retryPageOnNoPermission: "",
    preferredNumTableRows: null
  },
  mutations: {
    setPreferredNumTableRows(state, num) {
      if (!numberUtil.isNumber(num)) {
        return;
      }
      state.preferredNumTableRows = num;
      storage.setLocalItem(keyStorageRowsPerPage, num);
    },
    setAlertList(state, alertList) {
      state.alertList = alertList;
    },
    setAppSnackbar(state, options) {
      if (typeof options === "string") {
        state.appSnackbar.text = options;
        state.appSnackbar.navButton = null;
      } else {
        state.appSnackbar.text = options.text;
        if (options.navButton) {
          state.appSnackbar.navButton = options.navButton;
        }
      }
      state.appSnackbar.visible = true;
    },
    setAlertTemplates(state, alertTemplates) {
      state.alertTemplates = alertTemplates;
    },
    setUserProfile(state, userProfile) {
      state.userProfile = userProfile;
    },
    setGroups(state, groups) {
      state.groups = groups;
    },
    setDepartments(state, departments) {
      state.departments = departments;
    },
    setAlertSimMode(state, simMode) {
      state.alert.simMode = simMode;
    },
    setVoices(state, voices) {
      state.voices = voices;
    },
    setSelectedAlertGroups(state, groups) {
      state.alert.selectedGroups = groups;
    },
    setSelectedAlertContacts(state, contacts) {
      state.alert.selectedContacts = contacts;
    },
    setAlertReceivers(state, receivers) {
      state.alert.receivers = receivers;
    },
    setTagNameBasedOnLocale(state) {
      const tagName = config.getLocaleProperties().tagName;
      state.tagName = tagName;
    },
    setSsoMsLastUsedCode(state, code) {
      state.ssoMsLastUsedCode = code;
    },
    removeSelectedAlertGroup(state, groupId) {
      state.alert.selectedGroups = state.alert.selectedGroups.filter(
        selGroup => {
          return selGroup.Id !== groupId;
        }
      );
    },
    setAlertSettings(state, alertSettings) {
      state.alert.settings = alertSettings;
    },
    addAlertReceiver(state, receiver) {
      const updateState = newReceiver => {
        state.alert.receivers.unshift(newReceiver);
      };
      receiver.Vikarer = [];
      if (receiver.Vikar1 || receiver.Vikar2) {
        // Get deputy and store it on receiver. Before this was done for all
        // selected alert receivers with one API call after all receivers
        // was added from GroupPicker/ContactPicker. But that lead to some
        // bugs that was hard to find. Since state was updated with new
        // contacts and then a new update with all the deputies added to
        // the contacts. This way (fetching deputies for each receiver, if
        // receiver has a deputy (indicated by Vikar1/2)) is much safer.
        // Yes, there will be more API calls when there are more than one
        // contact with deputry, but it will never be a performance problem,
        // since  most contacts do not have a deputy.
        apiContact.getContactDeputies([receiver.Id]).then(res => {
          if (res.length) {
            receiver.Vikarer = res[0].Vikarer;
          }
          updateState(receiver);
        });
      } else {
        updateState(receiver);
      }
    },
    addSelectedContact(state, contact) {
      state.alert.selectedContacts.push(contact);
    },
    removeAlertReceiver(state, contact) {
      state.alert.receivers = state.alert.receivers.filter(receiver => {
        if (receiver._source.name === contact._source.name) {
          if (receiver._source.name === "group") {
            return receiver._source.contactId !== contact._source.contactId;
          } else if (receiver._source.name === "largeGroup") {
            return receiver._source.id !== contact._source.id;
          }
          return receiver._source.id !== contact._source.id;
        }
        return true;
      });
    },
    removeAlertReceiverByGroupId(state, groupId) {
      state.alert.receivers = filterReceivers(
        state.alert.receivers,
        "group",
        groupId
      );
      state.alert.receivers = filterReceivers(
        state.alert.receivers,
        "largeGroup",
        groupId
      );
    },
    removeAlertReceiverByContactId(state, contactId) {
      state.alert.receivers = filterReceivers(
        state.alert.receivers,
        "contact",
        contactId
      );
    },
    clearAlertReceivers(state) {
      state.alert.receivers = [];
      state.alert.selectedGroups = [];
      state.alert.selectedContacts = [];
    },
    clearAlert(state) {
      state.alert.settings = null;
      state.alert.receivers = [];
      state.alert.selectedGroups = [];
      state.alert.selectedContacts = [];
    },
    updateContacts(state) {
      // Since contacts are not stored in Vuex any more (because of
      // network performance issues we now let the API handle pagination, the
      // client has no common app state for contacts), we only trigger
      // event that a contact component **can** listen too
      state.contactsUpdate = Date.now();
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setRetryPageOnNoPermission(state, page) {
      state.retryPageOnNoPermission = page;
    }
  },
  getters: {
    getUserHasSmsCodeword(state) {
      if (state.userProfile && state.userProfile.Kodeord.length) {
        return state.userProfile.Kodeord.some(
          codewordProps => codewordProps.KeywordType === 0
        );
      }
      return false;
    },
    getPreferredNumTableRows(state) {
      if (state.preferredNumTableRows === null) {
        let value = storage.getLocalItem(keyStorageRowsPerPage);
        value = parseInt(value, 10);
        return value ? value : config.defaultTblRowsPerPage;
      }
      return state.preferredNumTableRows;
    },

    getUserFullName(state) {
      if (state.userProfile) {
        return state.userProfile.Bruker.BrukerNavn;
      }
      return "";
    },
    getCustomerHasDepartments(state) {
      // Using > 1 since the first element is the "show all" option that always
      // is present
      return !!(state.userProfile?._adminDepartments.length > 1);
    },
    getUserCompany(state) {
      if (state.userProfile) {
        return state.userProfile.Firmanavn;
      }
      return "";
    },
    getUserIsAdmin(state) {
      if (state.userProfile) {
        return state.userProfile.Bruker.Admin;
      }
      return false;
    },
    getUserHasADProvisioning(state) {
      if (state.userProfile) {
        return state.userProfile.Bruker.Tilgang.ADProvisioning;
      }
      return false;
    },
    /**
     * Gets default country for phone features.
     * We use the country code stored on the customer. Why? Because each
     * user writres *alert texts* typycally in the customers language, even
     * though it's not the users UI language
     * @param state
     * @returns {string}
     */
    getDefaultCountryPhoneCode(state) {
      if (state.userProfile) {
        return "+" + state.userProfile.Landskode;
      }
      return "";
    },
    /**
     * This state can be used to determine if logged in user should see
     * what department an item (contact, template etc) is connected to
     */
    getShowDepartmentInfoInLists(state) {
      const userProfile = state.userProfile;
      if (userProfile) {
        // Returns true if users has two or more departments.
        // Will not return true on only one department, since the users then
        // does not need to differentiate between departments
        if (userProfile.Bruker.Admin) {
          return userProfile._adminDepartments.length > 1;
        }
        // For non-admins the main department is set in field Avdeling, not the
        // Avdelinger array. So we check if length is > 0, not 1 as with admins
        return userProfile.Bruker.Avdelinger.length > 0;
      }
      return false;
    },
    getSelectedDepartmentName(state) {
      const selectedId = user.getSelectedDepartment();
      if (!selectedId) {
        return "";
      }
      if (!state.departments.length) {
        return "";
      }
      const department = state.departments.find(
        dep => dep.AvdelingId === selectedId
      );
      return department ? department.Navn : "";
    }
  },
  actions: {
    // updatePreferredNumTableRows(context, num) {
    //   if (context.state.preferredNumTableRows === null) {
    //     const value = storage.getLocalItem(keyStorageRowsPerPage);
    //     context.commit("setPreferredNumTableRows", value);
    //   } else {
    //     context.commit("setPreferredNumTableRows", num)
    //   }
    // },
    updateAlertList(context) {
      return new Promise(resolve => {
        apiAlert.getAlertList(500).then(alerts => {
          context.commit("setAlertList", alerts);
          resolve();
        });
      });
    },
    updateAlertTemplates(context) {
      apiAlertTemplates.getAll().then(templates => {
        context.commit("setAlertTemplates", templates);
      });
    },
    updateUserProfile(context, forceServerFetch) {
      return new Promise(resolve => {
        user.getProfile(forceServerFetch).then(userProfile => {
          context.commit("setUserProfile", userProfile);
          resolve(userProfile);
        });
      });
    },
    getUserPermissions(context) {
      return new Promise(resolve => {
        context.dispatch("updateUserProfile").then(userProfile => {
          resolve(userProfile.Bruker._permissions);
        });
      });
    },
    updateGroups(context, slient) {
      return new Promise(resolve => {
        let returnObject = { groups: null };
        apiGroup.getAll(slient).then(groups => {
          context.commit("setGroups", groups);
          returnObject.groups = groups;
          resolve(returnObject);
        });
      });
    },
    updateVoices(context) {
      return new Promise(resolve => {
        if (context.state.voices.length) {
          // Voices are almost never change, so used cached data if any
          resolve(context.state.voices);
        } else {
          apiVoice.getVoices().then(voices => {
            context.commit("setVoices", voices);
            resolve(voices);
          });
        }
      });
    },
    updateDepartments(context) {
      return new Promise(resolve => {
        department.getAll().then(departments => {
          context.commit("setDepartments", departments);
          resolve(departments);
        });
      });
    }
  },
  modules: {}
});

const filterReceivers = (receivers, sourceName, idToExclude) => {
  return receivers.filter(receiver => {
    return !(
      receiver._source.name === sourceName &&
      receiver._source.id === idToExclude
    );
  });
};
