import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import store from "@/store";
import apiSession from "@/api/v24/api.session";
import user from "@/util/user";

Vue.config.productionTip = false;

appSetup().then(() => {
  new Vue({
    router,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount("#app");

  store.commit("setTagNameBasedOnLocale");
});

async function appSetup() {
  await handleV24visitors();

  // As long as we are inside this function nothing will be rendered to the user since Vue is not mounted yet.
  // Therefore, a loading spinner is set in index.html so that the user knows that something is happening.
  // Only users with slow/no internet connections will see this spinner. App setup is now done and the spinner is hidden
  document.getElementById("vue-is-loading").style.display = "none";
}

// Handles any URL jump from V24 prod, test and failover
async function handleV24visitors() {
  const urlData = new URL(location.href);
  const simMode = !!urlData.searchParams.get("sim");
  const ticket = urlData.searchParams.get("ticket");
  if (ticket) {
    // Remove ticket from searchParams since we will end up here again
    // when the user gets redirected to a new route
    urlData.searchParams.set("ticket", "");
    console.log(`Using ticket (${ticket}) to authenticate`);
    try {
      const sessionId = await apiSession.exchangeTicketForSessionId(ticket);
      await setSession(sessionId, simMode);
    } catch (err) {
      console.error("Authentication failed. Invalid ticket", err);
      alert("Authentication failed. Invalid ticket");
    }
  }
}

const setSession = (sessionId, simMode) => {
  return user.setIsV24User(sessionId, simMode);
};
