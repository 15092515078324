import api from "./api";
import alertReceiverStatus from "@/util/alertReceiverStatus";
import user from "@/util/user";

const apiAlert = {
  register(alertParams) {
    return api.execute("StartGruppeVarsling", alertParams.getApiParams());
  },
  getAlertList(rowCount, pageIdx) {
    const params = {
      Paging: {
        RowCount: rowCount || 999999,
        PageIdx: pageIdx || 0
      },
      VarslingType: "R",
      Avdeling: user.getSelectedDepartment()
    };
    return api.execute("HentVarslingerType", params, "Varslinger");
  },
  /**
   * @name getAlertDetails
   * @param alertId
   * @param dataParams
   * Set field(s) to true to fetch corresponding data
   {
      Adresser: false,
      Mottakere: false,
      Statuslogg: false,
      Endringslogg: false,
      Statistikk: false,
      Saab: false
    }
   * @param i18n Used for better status text
   * @param pageNumber
   * @param numberOfRows
   * @param sortField
   * @param sortDesc
   * @returns {Promise | Promise<unknown>}
   */
  getAlertDetails(
    alertId,
    dataParams,
    i18n,
    pageNumber,
    numberOfRows,
    sortField = "",
    sortDesc = false
  ) {
    const params = {
      Paging: {
        RowCount: numberOfRows,
        PageIdx: pageNumber
      },
      VarslingId: alertId,
      Adressenr: 0,
      Data: dataParams,
      SorterFelt: sortField,
      SorterSynkende: sortDesc
    };
    return new Promise(resolve => {
      api.execute("HentVarslingLogg", params).then(alert => {
        const receiversWithImprovedStatus = [];
        if (alert.Mottakere) {
          alert.Mottakere.forEach(receiver => {
            receiver.Status = alertReceiverStatus.translate(
              alert.Varsling.VarslingStatus,
              alert.Varsling.Simulering,
              receiver.Status,
              i18n
            );
            receiversWithImprovedStatus.push(receiver);
          });
          alert.Mottakere = receiversWithImprovedStatus;
        }
        resolve(alert);
      });
    });
  },

  /**
   * @name searchAlertReceivers
   * @param alertId
   * @param searchString
   * @param pageNumber
   * @param numberOfRows
   * @param {String} sortField "Navn" or "Telefon"
   * @param sortDesc
   * @param {Number} responseCode Filter on respons code. -1 to show all
   * @returns {Promise | Promise<unknown>}
   */
  searchAlertReceivers(
    alertId,
    searchString,
    pageNumber,
    numberOfRows,
    sortField = "",
    sortDesc = false,
    responseCode = -1
  ) {
    const params = {
      Tekst: searchString,
      Paging: {
        RowCount: numberOfRows,
        PageIdx: pageNumber
      },
      VarslingId: alertId,
      SorterFelt: sortField,
      SorterSynkende: sortDesc,
      StarterMed: false,
      ResponsKode: responseCode
    };
    return new Promise(resolve => {
      // Workaround for missing Id value in API return data
      // https://app.zenhub.com/workspaces/varsling-24-5ba010a44b5806bc2be88fdb/issues/framweb/v24coreapi/385
      api.execute("SokVarslingMottakere", params).then(res => {
        res.Mottakere = res.Mottakere.map((item, index) => ({
          ...item,
          Id: index
        }));
        resolve(res);
      });
    });
  },

  cancelAlert(alertId) {
    return api.execute("StoppeVarsling", { VarslingId: alertId });
  },

  /**
   *
   * @param {String} fromDate YYYY-MM-DD HH:MM
   * @param {String} toDate YYYY-MM-DD HH:MM
   * @returns {Object} API stats object
   */
  getStats(fromDate, toDate, department = "") {
    const params = {
      FraDato: fromDate,
      TilDato: toDate,
      Avdeling: department
    };
    return api.execute("HentVarslingStatistikk", params);
  }
};

export default apiAlert;
